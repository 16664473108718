import React from "react";
import { Helmet } from 'react-helmet-async';import { Link } from "react-router-dom";

const CusService = () => {
      return (
        <>
            <Helmet>
               <title>GuruSoftware: Your IT Services Partner</title>
<meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

<meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:url" content="https://gurusoftware.in/" />
<meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:width" content="500" />
<meta property="og:image:height" content="500" />
<meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />
             
            </Helmet>

            <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">

                        <h2>CUSTOMIZED SOFTWARE</h2>
                    </div>
                    <div class="wpo-practice-wrap" style={{ marginBottom: "20px" }}>
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/crm.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/crm-development">CRM SOFTWARE DEVELOPMENT</Link></h2>
                                        <p>Streamline customer relationships and boost productivity with our tailored CRM solutions.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/crm-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/software-dev.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/software-development">SOFTWARE DEVELOPMENT</Link></h2>
                                        <p>From concept to deployment, we deliver scalable and innovative software solutions for your unique needs.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/software-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/edu.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/education-web-portal-development">EDUCATIONAL WEB PORTAL DEVELOPMENT</Link></h2>
                                        <p>Transform education with our feature-rich web portals, facilitating interactive learning experiences.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/education-web-portal-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/rest.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/restaurant-web-portal-development">RESTAURANT WEB PORTAL DEVELOPMENT</Link></h2>
                                        <p>Elevate your restaurant's online presence with user-friendly web portals for menus, reservations, and more.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/restaurant-web-portal-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/health.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/health-care-portal-development">HEALTH CARE PORTAL DEVELOPMENT</Link></h2>
                                        <p>Enhance patient care and management through secure and user-friendly healthcare web portals.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/health-care-portal-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/travel.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/travel-portal-development">TRAVEL PORTAL DEVELOPMENT</Link></h2>
                                        <p>We create comprehensive travel portals, offering seamless booking and travel information services.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/travel-portal-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/estate.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/real-estate-portal-development">REAL ESATE PORTAL DEVELOPMENT</Link></h2>
                                        <p>Attract property buyers and sellers with intuitive real estate portals showcasing listings and amenities.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/real-estate-portal-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/ecommerce.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/ecommerce-portal-development">E-COMMERCE WEBSITE DEVELOPMENT</Link></h2>
                                        <p>Boost sales and customer satisfaction with our e-commerce websites, designed for seamless shopping experiences.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/ecommerce-portal-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CusService