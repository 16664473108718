import React from "react";
import { Helmet } from 'react-helmet-async';import { Link } from "react-router-dom";
const Services = () => {
      return (
        <>
            <Helmet>
               <title>GuruSoftware: Your IT Services Partner</title>
<meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

<meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:url" content="https://gurusoftware.in/" />
<meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:width" content="500" />
<meta property="og:image:height" content="500" />
<meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />
             
            </Helmet>

            <section class="wpo-page-title">
                <div class="container">
                    <div class="row">
                        <div class="col col-xs-12">
                            <div class="wpo-breadcumb-wrap">
                                <h2>Services</h2>
                                <ol class="wpo-breadcumb-wrap">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Services</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">
                    <span>Our Services</span>
                        <h2 >Transforming Ideas Into Reality</h2>
                    </div>
                    <div class="wpo-practice-wrap" style={{ marginBottom: "20px" }}>
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-laptop-code"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/web-design-and-development-services">WEBSITE DEVELOPMENT</Link></h2>
                                        <p>Website development encompasses the creation and upkeep of websites, focusing on making
                                            them visually appealing, fast, and providing a seamless user experience.</p>

                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/web-design-and-development-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-mobile-alt"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/mobile-application-services">MOBILE APP DEVELOPMENT</Link></h2>
                                        <p>Mobile app development is the process of creating applications specifically designed for
                                            mobile devices. This intricate process entails the development of software tailored for
                                            small, wireless computing devices.</p>


                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/mobile-application-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-cog"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/custom-software-services">CUSTOMIZED SOFTWARE </Link></h2>
                                        <p>Customized software refers to computer programs or applications that are specifically
                                            designed and developed to meet the unique needs and requirements of a particular
                                            business or organization.
                                        </p>
                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/custom-software-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-rocket"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/digital-marketing-services">DIGITAL MARKETING</Link></h2>
                                        <p>Digital marketing is a strategic approach that utilizes various online channels to
                                            captivate, interact with, and convert customers on the internet.</p>
                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/digital-marketing-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services
