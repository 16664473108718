import React from "react";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";

const WebDeService = () => {
    return (
        <>

            <Helmet>
            <title>GuruSoftware: Your IT Services Partner</title>
<meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

<meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:url" content="https://gurusoftware.in/" />
<meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:width" content="500" />
<meta property="og:image:height" content="500" />
<meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />
             
            </Helmet>
            <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">

                        <h2>WEBSITE DEVELOPMENT</h2>
                    </div>
                    <div class="wpo-practice-wrap" style={{ marginBottom: "20px" }}>
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/web-design.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/website-design">WEBSITE DESIGN</Link></h2>
                                        <p>Our expert designers create visually stunning websites tailored to your brand, ensuring a captivating online presence.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "30px" }}>
                                        <Link to="/website-design" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/web-dev.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/website-development">WEBSITE DEVELOPMENT</Link></h2>
                                        <p>We bring your web vision to life with responsive and functional websites that drive engagement and conversions.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "30px" }}>
                                        <Link to="/website-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WebDeService