import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useRef } from "react";
import { Link } from "react-router-dom";

const Req = () => {
    const [show, setShow] = useState(false)
    const [cname, setCname] = useState("")

    const initialstate = {
        names: "",
        email: "",
        country: "",
        countryCode: "",
        bType: "",
        phone: "",
        checkboxValues: [], // Initialize as an empty array
        proName: "",
        budget: "",
        lDate: "",
        tDate: "",
        tCountry: "",
        refer: "",
        img: "",
        other: ""
    };

    const [state, setState] = useState(initialstate);
    const { names, email, country, countryCode, bType, phone, checkboxValues, proName, budget, lDate, tDate, tCountry, refer, img, other } = state;

    const changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === 'drone') {
            // If it's the drone field, toggle the checkbox value in the array
            const updatedDrone = [...checkboxValues];
            if (updatedDrone.includes(value)) {
                // Value already exists, remove it
                const index = updatedDrone.indexOf(value);
                if (index !== -1) {
                    updatedDrone.splice(index, 1);
                }
            } else {
                // Value doesn't exist, add it
                updatedDrone.push(value);
            }
            setState({ ...state, [name]: updatedDrone });
        } else {
            // For other fields, update the state as usual
            setState({ ...state, [name]: value });
        }
    };

    const [selectedOption, setSelectedOption] = useState("");

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const cnames = event.target.options[event.target.selectedIndex].text; // Get the selected option text
        state.countryCode = selectedValue
        setCname(cnames)
        // console.log(selectedValue1)
        setSelectedOption("+" + selectedValue)
        // this.setState({ selectedOption: selectedValue });
    }


    console.log(state)
    const check = () => {
        console.log("click")
        setShow(true)
    }


    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;

        setState((prevData) => {
            if (isChecked) {
                return {
                    ...prevData,
                    checkboxValues: [...prevData.checkboxValues, checkboxValue],
                };
            } else {
                return {
                    ...prevData,
                    checkboxValues: prevData.checkboxValues.filter(
                        (value) => value !== checkboxValue
                    ),
                };
            }
        });
    };

    const [verified, setVerified] = useState('');
    const [ercapacha, setErcapacha] = useState('')
    const [status, setStatus] = useState('')
    const [visible, setIsVisible] = useState(true);
    const captch = useRef(null);



    const key = '6LfqGJ8gAAAAAOGYrU5mnv6cZxePqeJ_u85A3t5L';

    function onChange() {
        setVerified(true);
    }


    const handleSubmit = (e) => {
    
        e.preventDefault();

        if (verified === true) {
            axios
                .post("https://www.gurusoftware.in/requirement.php", {
                    names,
                    email,
                    lDate,
                    refer,
                    proName, countryCode, bType, phone, budget, tCountry, img, tDate, checkboxValues, other, cname
                    // message
                })
                .then(function (data) {
                    console.log(data.data);
                    if (data.data.message === "Message Sent") {
                        captch.current.reset()
                        setIsVisible(true);

                        setState({
                            names: "",
                            email: "",
                            country: "",
                            countryCode: "",
                            bType: "",
                            phone: "",
                            checkboxValues: [], // Initialize as an empty array
                            proName: "",
                            budget: "",
                            lDate: "",
                            tDate: "",
                            tCountry: "",
                            refer: "",
                            img: "",
                            other: ""
                        });
                        setSelectedOption("")
                        setStatus("Message sent successfully. We will connect you soon")
                        setErcapacha("")

                    }
                })
        } else {
            setErcapacha("Please Verify CAPTCHA")
            setIsVisible(true);
            setTimeout(function () {
                setIsVisible(false);
            }, 3000);
            console.log("fail");

        }

    };
    return (
        <>
            <Helmet>
                <title>GuruSoftware: Your IT Services Partner</title>
                <meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

                <meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
                <meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
                <meta property="og:url" content="https://gurusoftware.in/" />
                <meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
                <meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

            </Helmet>

            <div class="talk-area ptb-100 ">
                <div class="container cards3" style={{ background: "#FEF8E1", marginBottom: "100px", marginTop: "50px" }}>
                    <div class="row align-items-center">


                        <div class="container col-md-9">
                            <div class="talk-content margin-zero" style={{ marginTop: "90px" }}>
                                <span>LET'S TALK</span>
                                <h3 style={{ marginBottom: "7px" }}>We Would Like To Hear From You Anytime <span
                                    class="overlay"></span></h3>

                                <form id="contactFormTwo" name="mail" onSubmit={handleSubmit} enctype='multipart/form-data'>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <span>Name/Company Name</span>
                                                <input type="text" name="names" id="names" class="form-control" value={state
                                                .names} required onChange={changeHandler}
                                                    data-error="Please enter your name/Company name"
                                                    placeholder="Your name/Company name" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6 mb-10">
                                            <div class="form-group">
                                                <span>Email</span>
                                                <input type="text" name="email" id="email" value={state.email} class="form-control" required onChange={changeHandler}
                                                    data-error="Please enter your email" placeholder="Your email address" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <span>Country</span>
                                                <select name="country" id="Choose" value={state.country} onChange={event => handleSelectChange(event)}
                                                    class="form-control country">
                                                    <option>Select your Country</option>
                                                    <option data-countryCode="GB" value="44">UK </option>
                                                    <option data-countryCode="US" value="1">USA</option>

                                                    <option data-countryCode="DZ" value="213">Algeria</option>
                                                    <option data-countryCode="AD" value="376">Andorra </option>
                                                    <option data-countryCode="AO" value="244">Angola </option>
                                                    <option data-countryCode="AI" value="1264">Anguilla</option>
                                                    <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda
                                                    </option>
                                                    <option data-countryCode="AR" value="54">Argentina </option>
                                                    <option data-countryCode="AM" value="374">Armenia </option>
                                                    <option data-countryCode="AW" value="297">Aruba </option>
                                                    <option data-countryCode="AU" value="61">Australia </option>
                                                    <option data-countryCode="AT" value="43">Austria</option>
                                                    <option data-countryCode="AZ" value="994">Azerbaijan </option>
                                                    <option data-countryCode="BS" value="1242">Bahamas </option>
                                                    <option data-countryCode="BH" value="973">Bahrain</option>
                                                    <option data-countryCode="BD" value="880">Bangladesh </option>
                                                    <option data-countryCode="BB" value="1246">Barbados</option>
                                                    <option data-countryCode="BY" value="375">Belarus </option>
                                                    <option data-countryCode="BE" value="32">Belgium </option>
                                                    <option data-countryCode="BZ" value="501">Belize </option>
                                                    <option data-countryCode="BJ" value="229">Benin </option>
                                                    <option data-countryCode="BM" value="1441">Bermuda </option>
                                                    <option data-countryCode="BT" value="975">Bhutan </option>
                                                    <option data-countryCode="BO" value="591">Bolivia </option>
                                                    <option data-countryCode="BA" value="387">Bosnia Herzegovina </option>
                                                    <option data-countryCode="BW" value="267">Botswana </option>
                                                    <option data-countryCode="BR" value="55">Brazil </option>
                                                    <option data-countryCode="BN" value="673">Brunei </option>
                                                    <option data-countryCode="BG" value="359">Bulgaria </option>
                                                    <option data-countryCode="BF" value="226">Burkina Faso </option>
                                                    <option data-countryCode="BI" value="257">Burundi</option>
                                                    <option data-countryCode="KH" value="855">Cambodia </option>
                                                    <option data-countryCode="CM" value="237">Cameroon </option>
                                                    <option data-countryCode="CA" value="1">Canada </option>
                                                    <option data-countryCode="CV" value="238">Cape Verde Islands </option>
                                                    <option data-countryCode="KY" value="1345">Cayman Islands</option>
                                                    <option data-countryCode="CF" value="236">Central African Republic
                                                    </option>
                                                    <option data-countryCode="CL" value="56">Chile</option>
                                                    <option data-countryCode="CN" value="86">China</option>
                                                    <option data-countryCode="CO" value="57">Colombia</option>
                                                    <option data-countryCode="KM" value="269">Comoros</option>
                                                    <option data-countryCode="CG" value="242">Congo</option>
                                                    <option data-countryCode="CK" value="682">Cook Islands</option>
                                                    <option data-countryCode="CR" value="506">Costa Rica</option>
                                                    <option data-countryCode="HR" value="385">Croatia </option>
                                                    <option data-countryCode="CU" value="53">Cuba </option>
                                                    <option data-countryCode="CY" value="90392">Cyprus North</option>
                                                    <option data-countryCode="CY" value="357">Cyprus South </option>
                                                    <option data-countryCode="CZ" value="42">Czech Republic</option>
                                                    <option data-countryCode="DK" value="45">Denmark</option>
                                                    <option data-countryCode="DJ" value="253">Djibouti</option>
                                                    <option data-countryCode="DM" value="1809">Dominica </option>
                                                    <option data-countryCode="DO" value="1809">Dominican Republic
                                                    </option>
                                                    <option data-countryCode="EC" value="593">Ecuador</option>
                                                    <option data-countryCode="EG" value="20">Egypt </option>
                                                    <option data-countryCode="SV" value="503">El Salvador </option>
                                                    <option data-countryCode="GQ" value="240">Equatorial Guinea</option>
                                                    <option data-countryCode="ER" value="291">Eritrea </option>
                                                    <option data-countryCode="EE" value="372">Estonia </option>
                                                    <option data-countryCode="ET" value="251">Ethiopia </option>
                                                    <option data-countryCode="FK" value="500">Falkland Islands </option>
                                                    <option data-countryCode="FO" value="298">Faroe Islands </option>
                                                    <option data-countryCode="FJ" value="679">Fiji </option>
                                                    <option data-countryCode="FI" value="358">Finland</option>
                                                    <option data-countryCode="FR" value="33">France </option>
                                                    <option data-countryCode="GF" value="594">French Guiana</option>
                                                    <option data-countryCode="PF" value="689">French Polynesia </option>
                                                    <option data-countryCode="GA" value="241">Gabon </option>
                                                    <option data-countryCode="GM" value="220">Gambia </option>
                                                    <option data-countryCode="GE" value="7880">Georgia </option>
                                                    <option data-countryCode="DE" value="49">Germany</option>
                                                    <option data-countryCode="GH" value="233">Ghana </option>
                                                    <option data-countryCode="GI" value="350">Gibraltar</option>
                                                    <option data-countryCode="GR" value="30">Greece</option>
                                                    <option data-countryCode="GL" value="299">Greenland </option>
                                                    <option data-countryCode="GD" value="1473">Grenada </option>
                                                    <option data-countryCode="GP" value="590">Guadeloupe </option>
                                                    <option data-countryCode="GU" value="671">Guam </option>
                                                    <option data-countryCode="GT" value="502">Guatemala </option>
                                                    <option data-countryCode="GN" value="224">Guinea </option>
                                                    <option data-countryCode="GW" value="245">Guinea - Bissau </option>
                                                    <option data-countryCode="GY" value="592">Guyana </option>
                                                    <option data-countryCode="HT" value="509">Haiti </option>
                                                    <option data-countryCode="HN" value="504">Honduras </option>
                                                    <option data-countryCode="HK" value="852">Hong Kong </option>
                                                    <option data-countryCode="HU" value="36">Hungary </option>
                                                    <option data-countryCode="IS" value="354">Iceland </option>
                                                    <option data-countryCode="IN" value="91">India </option>
                                                    <option data-countryCode="ID" value="62">Indonesia </option>
                                                    <option data-countryCode="IR" value="98">Iran</option>
                                                    <option data-countryCode="IQ" value="964">Iraq </option>
                                                    <option data-countryCode="IE" value="353">Ireland </option>
                                                    <option data-countryCode="IL" value="972">Israel</option>
                                                    <option data-countryCode="IT" value="39">Italy</option>
                                                    <option data-countryCode="JM" value="1876">Jamaica </option>
                                                    <option data-countryCode="JP" value="81">Japan </option>
                                                    <option data-countryCode="JO" value="962">Jordan </option>
                                                    <option data-countryCode="KZ" value="7">Kazakhstan </option>
                                                    <option data-countryCode="KE" value="254">Kenya </option>
                                                    <option data-countryCode="KI" value="686">Kiribati </option>
                                                    <option data-countryCode="KP" value="850">Korea North </option>
                                                    <option data-countryCode="KR" value="82">Korea South </option>
                                                    <option data-countryCode="KW" value="965">Kuwait </option>
                                                    <option data-countryCode="KG" value="996">Kyrgyzstan </option>
                                                    <option data-countryCode="LA" value="856">Laos </option>
                                                    <option data-countryCode="LV" value="371">Latvia </option>
                                                    <option data-countryCode="LB" value="961">Lebanon </option>
                                                    <option data-countryCode="LS" value="266">Lesotho </option>
                                                    <option data-countryCode="LR" value="231">Liberia </option>
                                                    <option data-countryCode="LY" value="218">Libya </option>
                                                    <option data-countryCode="LI" value="417">Liechtenstein </option>
                                                    <option data-countryCode="LT" value="370">Lithuania </option>
                                                    <option data-countryCode="LU" value="352">Luxembourg </option>
                                                    <option data-countryCode="MO" value="853">Macao </option>
                                                    <option data-countryCode="MK" value="389">Macedonia </option>
                                                    <option data-countryCode="MG" value="261">Madagascar </option>
                                                    <option data-countryCode="MW" value="265">Malawi </option>
                                                    <option data-countryCode="MY" value="60">Malaysia</option>
                                                    <option data-countryCode="MV" value="960">Maldives </option>
                                                    <option data-countryCode="ML" value="223">Mali </option>
                                                    <option data-countryCode="MT" value="356">Malta </option>
                                                    <option data-countryCode="MH" value="692">Marshall Islands </option>
                                                    <option data-countryCode="MQ" value="596">Martinique </option>
                                                    <option data-countryCode="MR" value="222">Mauritania </option>
                                                    <option data-countryCode="YT" value="269">Mayotte </option>
                                                    <option data-countryCode="MX" value="52">Mexico </option>
                                                    <option data-countryCode="FM" value="691">Micronesia</option>
                                                    <option data-countryCode="MD" value="373">Moldova </option>
                                                    <option data-countryCode="MC" value="377">Monaco </option>
                                                    <option data-countryCode="MN" value="976">Mongolia </option>
                                                    <option data-countryCode="MS" value="1664">Montserrat </option>
                                                    <option data-countryCode="MA" value="212">Morocco </option>
                                                    <option data-countryCode="MZ" value="258">Mozambique </option>
                                                    <option data-countryCode="MN" value="95">Myanmar </option>
                                                    <option data-countryCode="NA" value="264">Namibia </option>
                                                    <option data-countryCode="NR" value="674">Nauru </option>
                                                    <option data-countryCode="NP" value="977">Nepal</option>
                                                    <option data-countryCode="NL" value="31">Netherlands </option>
                                                    <option data-countryCode="NC" value="687">New Caledonia </option>
                                                    <option data-countryCode="NZ" value="64">New Zealand </option>
                                                    <option data-countryCode="NI" value="505">Nicaragua</option>
                                                    <option data-countryCode="NE" value="227">Niger </option>
                                                    <option data-countryCode="NG" value="234">Nigeria </option>
                                                    <option data-countryCode="NU" value="683">Niue </option>
                                                    <option data-countryCode="NF" value="672">Norfolk Islands</option>
                                                    <option data-countryCode="NP" value="670">Northern Marianas </option>
                                                    <option data-countryCode="NO" value="47">Norway </option>
                                                    <option data-countryCode="OM" value="968">Oman </option>
                                                    <option data-countryCode="PW" value="680">Palau </option>
                                                    <option data-countryCode="PA" value="507">Panama </option>
                                                    <option data-countryCode="PG" value="675">Papua New Guinea</option>
                                                    <option data-countryCode="PY" value="595">Paraguay </option>
                                                    <option data-countryCode="PE" value="51">Peru </option>
                                                    <option data-countryCode="PH" value="63">Philippines</option>
                                                    <option data-countryCode="PL" value="48">Poland </option>
                                                    <option data-countryCode="PT" value="351">Portugal </option>
                                                    <option data-countryCode="PR" value="1787">Puerto Rico</option>
                                                    <option data-countryCode="QA" value="974">Qatar </option>
                                                    <option data-countryCode="RE" value="262">Reunion</option>
                                                    <option data-countryCode="RO" value="40">Romania</option>
                                                    <option data-countryCode="RU" value="7">Russia </option>
                                                    <option data-countryCode="RW" value="250">Rwanda </option>
                                                    <option data-countryCode="SM" value="378">San Marino </option>
                                                    <option data-countryCode="ST" value="239">Sao Tome &amp; Principe
                                                    </option>
                                                    <option data-countryCode="SA" value="966">Saudi Arabia </option>
                                                    <option data-countryCode="SN" value="221">Senegal</option>
                                                    <option data-countryCode="CS" value="381">Serbia </option>
                                                    <option data-countryCode="SC" value="248">Seychelles </option>
                                                    <option data-countryCode="SL" value="232">Sierra Leone </option>
                                                    <option data-countryCode="SG" value="65">Singapore </option>
                                                    <option data-countryCode="SK" value="421">Slovak Republic</option>
                                                    <option data-countryCode="SI" value="386">Slovenia</option>
                                                    <option data-countryCode="SB" value="677">Solomon Islands</option>
                                                    <option data-countryCode="SO" value="252">Somalia </option>
                                                    <option data-countryCode="ZA" value="27">South Africa </option>
                                                    <option data-countryCode="ES" value="34">Spain</option>
                                                    <option data-countryCode="LK" value="94">Sri Lanka</option>
                                                    <option data-countryCode="SH" value="290">St. Helena </option>
                                                    <option data-countryCode="KN" value="1869">St. Kitts</option>
                                                    <option data-countryCode="SC" value="1758">St. Lucia </option>
                                                    <option data-countryCode="SD" value="249">Sudan </option>
                                                    <option data-countryCode="SR" value="597">Suriname </option>
                                                    <option data-countryCode="SZ" value="268">Swaziland </option>
                                                    <option data-countryCode="SE" value="46">Sweden </option>
                                                    <option data-countryCode="CH" value="41">Switzerland </option>
                                                    <option data-countryCode="SI" value="963">Syria </option>
                                                    <option data-countryCode="TW" value="886">Taiwan </option>
                                                    <option data-countryCode="TJ" value="7">Tajikstan</option>
                                                    <option data-countryCode="TH" value="66">Thailand</option>
                                                    <option data-countryCode="TG" value="228">Togo </option>
                                                    <option data-countryCode="TO" value="676">Tonga </option>
                                                    <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago
                                                    </option>
                                                    <option data-countryCode="TN" value="216">Tunisia </option>
                                                    <option data-countryCode="TR" value="90">Turkey</option>
                                                    <option data-countryCode="TM" value="7">Turkmenistan </option>
                                                    <option data-countryCode="TM" value="993">Turkmenistan </option>
                                                    <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands
                                                    </option>
                                                    <option data-countryCode="TV" value="688">Tuvalu </option>
                                                    <option data-countryCode="UG" value="256">Uganda </option>
                                                    {/* <!-- <option data-countryCode="GB" value="44">UK (+44)</option> --> */}
                                                    <option data-countryCode="UA" value="380">Ukraine </option>
                                                    <option data-countryCode="AE" value="971">United Arab Emirates
                                                    </option>
                                                    <option data-countryCode="UY" value="598">Uruguay </option>
                                                    {/* <!-- <option data-countryCode="US" value="1">USA (+1)</option> --> */}
                                                    <option data-countryCode="UZ" value="7">Uzbekistan</option>
                                                    <option data-countryCode="VU" value="678">Vanuatu </option>
                                                    <option data-countryCode="VA" value="379">Vatican City </option>
                                                    <option data-countryCode="VE" value="58">Venezuela </option>
                                                    <option data-countryCode="VN" value="84">Vietnam </option>
                                                    <option data-countryCode="VG" value="84">Virgin Islands - British
                                                    </option>
                                                    <option data-countryCode="VI" value="84">Virgin Islands - US
                                                    </option>
                                                    <option data-countryCode="WF" value="681">Wallis &amp; Futuna
                                                    </option>
                                                    <option data-countryCode="YE" value="969">Yemen (North)</option>
                                                    <option data-countryCode="YE" value="967">Yemen (South)</option>
                                                    <option data-countryCode="ZM" value="260">Zambia </option>
                                                    <option data-countryCode="ZW" value="263">Zimbabwe </option>

                                                </select>
                                                <p id="demo1"></p>
                                                {/* <script>
                                                    var x_ip = "";
                                                    function Func_a() {
                                                var x_ip = document.getElementById("Choose").innerText;
                                                    const myArray = x_ip.split("@");
                                                    console.log(x_ip);
                                                    const select = document.getElementById('number');
                                                    select.value = "+" + myArray[0];
                                                    console.log(myArray[1])
                                                    console.log(select.value);
                                            }
                                                </script> */}
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        {/* <!--<div class="row col-lg-6 col-md-6">--> */}
                                        <div class="col-lg-2 col-md-2">
                                            <div class="form-group">
                                                <span>Country Code</span>

                                                <input type="tel" name="countryCode" class="form-control" value={selectedOption} required id="number" onChange={changeHandler}
                                                    data-error="Please enter your phone" readonly placeholder="" />
                                                <div class="help-block with-errors"></div>

                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <span>Contact Number</span>
                                                <input type="tel" name="phone" class="form-control" required value={state.phone} onChange={changeHandler}
                                                    data-error="Please enter your contact" placeholder="Your Phone" />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        {/* <!--</div>--> */}

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <span>Type Of Business</span>
                                                <select name="bType" id="" value={state.bType} class="form-control myDropDown" onChange={changeHandler}>
                                                    <option>Select Type Of Business</option>
                                                    <option>Art & Entertainment</option>
                                                    <option>Autos & Vehicles</option>
                                                    <option>Beaty & Fitness</option>
                                                    <option>Books & Literature</option>
                                                    <option>Bussiness & Industrial</option>
                                                    <option>Computer & Electronics</option>
                                                    <option>Finance</option>
                                                    <option>Food & Drink</option>
                                                    <option>Games</option>
                                                    <option>Health</option>
                                                    <option>Hobbies & Leisure</option>
                                                    <option>Home & Garden</option>
                                                    <option>Internet & Telecom</option>
                                                    <option>Jobs & Education</option>
                                                    <option>Law & Government</option>
                                                    <option>News</option>
                                                    <option>Marketing & Advertisement</option>
                                                    <option>Online Communities</option>
                                                    <option>People & Society</option>
                                                    <option>Pets & Animals</option>
                                                    <option>Real Estate</option>
                                                    <option>Science & Technology</option>
                                                    <option>Shopping</option>
                                                    <option>Sports</option>
                                                    <option>Travels</option>
                                                    <option>Other</option>
                                                </select>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <span><b>Services Interested In</b></span>
                                            <div class="row" style={{ marginTop: "25px" }}>
                                                <div class="col-lg-4 col-md-6">
                                                    <fieldset multiple>

                                                        <input type="checkbox" id="Digital Marketing" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Digital Marketing" />
                                                        <label for="Digital Marketing">Digital Marketing</label><br />
                                                        <input type="checkbox" id="Website Design" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Website Design" />
                                                        <label for="Website Design">Website Design</label><br />
                                                        <input type="checkbox" id="E-commerce Website Development"
                                                            name="drone[]" onChange={handleCheckboxChange} value="E-commerce Website Development" />
                                                        <label for="E-commerce Website Development">E-commerce Website
                                                            Development</label><br /><br />

                                                        <input type="checkbox" id="Hybrid App Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Hybrid App Development" />
                                                        <label for="Hybrid App Development">Hybrid App Development</label><br />
                                                        <input type="checkbox" id="Native App Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Native App Development" />
                                                        <label for="Native App Development">Native App Development</label><br />

                                                        <input type="checkbox" id="School Management Software" name="drone[]" onChange={handleCheckboxChange}
                                                            value="School Management Software" />
                                                        <label for="School Management Software">School Management
                                                            Software</label><br />
                                                        <input type="checkbox" id="Brand Consulting" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Brand Consulting" />
                                                        <label for="Brand Consulting">Brand Consulting</label><br />
                                                        <input type="checkbox" id="Real Estate Software" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Real Estate Software" />
                                                        <label for="Real Estate Software">Real Estate Software</label><br />
                                                        <input type="checkbox" id="Inventory Management Software" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Inventory Management Software" />
                                                        <label for="Inventory Management Software">Inventory Management
                                                            Software</label><br />
                                                        <input type="checkbox" id="Crm Web Portal" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Crm Web Portal" />
                                                        <label for="Crm Web Portal">Crm Web Portal</label><br />
                                                    </fieldset>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <fieldset>
                                                        <input type="checkbox" id="Software Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Software Development" />
                                                        <label for="Software Development">Software Development</label><br />
                                                        <input type="checkbox" id="Crm Software Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Crm Software Development" />
                                                        <label for="Crm Software Development">Crm Software
                                                            Development</label><br />
                                                        <input type="checkbox" id="Search Engine Marketing" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Search Engine Marketing" />
                                                        <label for="Search Engine Marketing">Search Engine
                                                            Marketing</label><br /><br />

                                                        <input type="checkbox" id="Pay Per Click Management" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Pay Per Click Management" />
                                                        <label for="Pay Per Click Management">Pay Per Click
                                                            Management</label><br />
                                                        <input type="checkbox" id="Social Media Marketing" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Social Media Marketing" />
                                                        <label for="Social Media Marketing">Social Media Marketing</label><br />
                                                        <input type="checkbox" id="Seo Copywriting" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Seo Copywriting" />
                                                        <label for="Seo Copywriting">Seo Copywriting</label><br />

                                                        <input type="checkbox" id="Real Estate Web Portal Development"
                                                            name="drone[]" onChange={handleCheckboxChange} value="Real Estate Web Portal Development" />
                                                        <label for="Real Estate Web Portal Development">Real Estate Web Portal
                                                            Development</label><br />
                                                        <input type="checkbox" id="Brouchre Design" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Brouchre Design" />
                                                        <label for="Brouchre Design">Brouchre Design</label><br />
                                                        <input type="checkbox" id="Hr And Payroll Management" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Hr And Payroll Management" />
                                                        <label for="Hr And Payroll Management">Hr And Payroll
                                                            Management</label><br />
                                                        <input type="checkbox" id="Hospital Management Software" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Hospital Management Software" />
                                                        <label for="Hospital Management Software">Hospital Management
                                                            Software</label><br />
                                                    </fieldset>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <fieldset>
                                                        <input type="checkbox" id="Mobile Apps Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Mobile Apps Development" />
                                                        <label for="Mobile Apps Development">Mobile Apps Development</label><br />
                                                        <input type="checkbox" id="Ui Ux Design" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Ui Ux Design" />
                                                        <label for="Ui Ux Design">Ui Ux Design</label><br />
                                                        <input type="checkbox" id="Logo Design" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Logo Design" />
                                                        <label for="Logo Design">Logo Design</label><br /><br />
                                                        <input type="checkbox" id="Educational Web Portal Development"
                                                            name="drone[]" onChange={handleCheckboxChange} value="Educational Web Portal Development" />
                                                        <label for="Educational Web Portal Development">Educational Web Portal
                                                            Development</label><br />
                                                        <input type="checkbox" id="Restaurant Web Portal Development"
                                                            name="drone[]" onChange={handleCheckboxChange} value="Restaurant Web Portal Development" />
                                                        <label for="Restaurant Web Portal Development">Restaurant Web Portal
                                                            Development</label><br />
                                                        <input type="checkbox" id="Healthcare Portal Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Healthcare Portal Development" />
                                                        <label for="Healthcare Portal Development">Healthcare Portal
                                                            Development</label><br />
                                                        <input type="checkbox" id="Travel Portal Development" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Travel Portal Development" />
                                                        <label for="Travel Portal Development">Travel Portal
                                                            Development</label><br />
                                                        <input type="checkbox" id="Jewellery Software" name="drone[]" onChange={handleCheckboxChange}
                                                            value="Jewellery Software" />
                                                        <label for="Jewellery Software">Jewellery Software</label><br />



                                                        <input type="checkbox" class="other" onClick={check}
                                                            id="Other" name="other" onChange={changeHandler} value="Other" />
                                                        <label for="Other">Other</label><br />

                                                    </fieldset>
                                                </div>
                                            </div>

                                        </div>

                                        {show ? (<> <div class="answer col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <span>Services Interested In (If Other)</span>
                                                <input type="text" name="other" class="form-control" value={state.other} onChange={changeHandler}
                                                    data-error="Please enter your email" placeholder="" />
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div></>) : ""}



                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <span>Project Name (optional)</span>
                                                <input type="text" name="proName" class="form-control" value={state.proName}
                                                    data-error="Please enter your Project name" onChange={changeHandler} placeholder="Your Project Name" />
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <span>Budget in $ (optional)</span>
                                                <input type="text" name="budget" class="form-control" value={state.budget} onChange={changeHandler} placeholder="Your Budget" />
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6">
                                            <div class="form-group">
                                                <span>Launch Date (optional)</span>
                                                <input type="date" name="lDate" class="form-control" value={state.lDate} onChange={changeHandler}
                                                    data-error="Please enter your Launch Date" placeholder="Your Project Name" />
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6">
                                            <div class="form-group">
                                                <span>Target Audience (optional)</span>
                                                <input type="text" name="tDate" class="form-control" value={state.tDate} onChange={changeHandler}
                                                    placeholder="Your Target Audience" />
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6">
                                            <div class="form-group">
                                                <span>Target Country (optional)</span>
                                                <select name="tCountry" class="form-control target" onChange={changeHandler}>
                                                    <option>Select your Country</option>
                                                    <option data-countryCode="GB" value="44">UK </option>
                                                    <option data-countryCode="US" value="1">USA</option>

                                                    <option data-countryCode="DZ" value="213">Algeria</option>
                                                    <option data-countryCode="AD" value="376">Andorra </option>
                                                    <option data-countryCode="AO" value="244">Angola </option>
                                                    <option data-countryCode="AI" value="1264">Anguilla</option>
                                                    <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda
                                                    </option>
                                                    <option data-countryCode="AR" value="54">Argentina </option>
                                                    <option data-countryCode="AM" value="374">Armenia </option>
                                                    <option data-countryCode="AW" value="297">Aruba </option>
                                                    <option data-countryCode="AU" value="61">Australia </option>
                                                    <option data-countryCode="AT" value="43">Austria</option>
                                                    <option data-countryCode="AZ" value="994">Azerbaijan </option>
                                                    <option data-countryCode="BS" value="1242">Bahamas </option>
                                                    <option data-countryCode="BH" value="973">Bahrain</option>
                                                    <option data-countryCode="BD" value="880">Bangladesh </option>
                                                    <option data-countryCode="BB" value="1246">Barbados</option>
                                                    <option data-countryCode="BY" value="375">Belarus </option>
                                                    <option data-countryCode="BE" value="32">Belgium </option>
                                                    <option data-countryCode="BZ" value="501">Belize </option>
                                                    <option data-countryCode="BJ" value="229">Benin </option>
                                                    <option data-countryCode="BM" value="1441">Bermuda </option>
                                                    <option data-countryCode="BT" value="975">Bhutan </option>
                                                    <option data-countryCode="BO" value="591">Bolivia </option>
                                                    <option data-countryCode="BA" value="387">Bosnia Herzegovina </option>
                                                    <option data-countryCode="BW" value="267">Botswana </option>
                                                    <option data-countryCode="BR" value="55">Brazil </option>
                                                    <option data-countryCode="BN" value="673">Brunei </option>
                                                    <option data-countryCode="BG" value="359">Bulgaria </option>
                                                    <option data-countryCode="BF" value="226">Burkina Faso </option>
                                                    <option data-countryCode="BI" value="257">Burundi</option>
                                                    <option data-countryCode="KH" value="855">Cambodia </option>
                                                    <option data-countryCode="CM" value="237">Cameroon </option>
                                                    <option data-countryCode="CA" value="1">Canada </option>
                                                    <option data-countryCode="CV" value="238">Cape Verde Islands </option>
                                                    <option data-countryCode="KY" value="1345">Cayman Islands</option>
                                                    <option data-countryCode="CF" value="236">Central African Republic
                                                    </option>
                                                    <option data-countryCode="CL" value="56">Chile</option>
                                                    <option data-countryCode="CN" value="86">China</option>
                                                    <option data-countryCode="CO" value="57">Colombia</option>
                                                    <option data-countryCode="KM" value="269">Comoros</option>
                                                    <option data-countryCode="CG" value="242">Congo</option>
                                                    <option data-countryCode="CK" value="682">Cook Islands</option>
                                                    <option data-countryCode="CR" value="506">Costa Rica</option>
                                                    <option data-countryCode="HR" value="385">Croatia </option>
                                                    <option data-countryCode="CU" value="53">Cuba </option>
                                                    <option data-countryCode="CY" value="90392">Cyprus North</option>
                                                    <option data-countryCode="CY" value="357">Cyprus South </option>
                                                    <option data-countryCode="CZ" value="42">Czech Republic</option>
                                                    <option data-countryCode="DK" value="45">Denmark</option>
                                                    <option data-countryCode="DJ" value="253">Djibouti</option>
                                                    <option data-countryCode="DM" value="1809">Dominica </option>
                                                    <option data-countryCode="DO" value="1809">Dominican Republic
                                                    </option>
                                                    <option data-countryCode="EC" value="593">Ecuador</option>
                                                    <option data-countryCode="EG" value="20">Egypt </option>
                                                    <option data-countryCode="SV" value="503">El Salvador </option>
                                                    <option data-countryCode="GQ" value="240">Equatorial Guinea</option>
                                                    <option data-countryCode="ER" value="291">Eritrea </option>
                                                    <option data-countryCode="EE" value="372">Estonia </option>
                                                    <option data-countryCode="ET" value="251">Ethiopia </option>
                                                    <option data-countryCode="FK" value="500">Falkland Islands </option>
                                                    <option data-countryCode="FO" value="298">Faroe Islands </option>
                                                    <option data-countryCode="FJ" value="679">Fiji </option>
                                                    <option data-countryCode="FI" value="358">Finland</option>
                                                    <option data-countryCode="FR" value="33">France </option>
                                                    <option data-countryCode="GF" value="594">French Guiana</option>
                                                    <option data-countryCode="PF" value="689">French Polynesia </option>
                                                    <option data-countryCode="GA" value="241">Gabon </option>
                                                    <option data-countryCode="GM" value="220">Gambia </option>
                                                    <option data-countryCode="GE" value="7880">Georgia </option>
                                                    <option data-countryCode="DE" value="49">Germany</option>
                                                    <option data-countryCode="GH" value="233">Ghana </option>
                                                    <option data-countryCode="GI" value="350">Gibraltar</option>
                                                    <option data-countryCode="GR" value="30">Greece</option>
                                                    <option data-countryCode="GL" value="299">Greenland </option>
                                                    <option data-countryCode="GD" value="1473">Grenada </option>
                                                    <option data-countryCode="GP" value="590">Guadeloupe </option>
                                                    <option data-countryCode="GU" value="671">Guam </option>
                                                    <option data-countryCode="GT" value="502">Guatemala </option>
                                                    <option data-countryCode="GN" value="224">Guinea </option>
                                                    <option data-countryCode="GW" value="245">Guinea - Bissau </option>
                                                    <option data-countryCode="GY" value="592">Guyana </option>
                                                    <option data-countryCode="HT" value="509">Haiti </option>
                                                    <option data-countryCode="HN" value="504">Honduras </option>
                                                    <option data-countryCode="HK" value="852">Hong Kong </option>
                                                    <option data-countryCode="HU" value="36">Hungary </option>
                                                    <option data-countryCode="IS" value="354">Iceland </option>
                                                    <option data-countryCode="IN" value="91">India </option>
                                                    <option data-countryCode="ID" value="62">Indonesia </option>
                                                    <option data-countryCode="IR" value="98">Iran</option>
                                                    <option data-countryCode="IQ" value="964">Iraq </option>
                                                    <option data-countryCode="IE" value="353">Ireland </option>
                                                    <option data-countryCode="IL" value="972">Israel</option>
                                                    <option data-countryCode="IT" value="39">Italy</option>
                                                    <option data-countryCode="JM" value="1876">Jamaica </option>
                                                    <option data-countryCode="JP" value="81">Japan </option>
                                                    <option data-countryCode="JO" value="962">Jordan </option>
                                                    <option data-countryCode="KZ" value="7">Kazakhstan </option>
                                                    <option data-countryCode="KE" value="254">Kenya </option>
                                                    <option data-countryCode="KI" value="686">Kiribati </option>
                                                    <option data-countryCode="KP" value="850">Korea North </option>
                                                    <option data-countryCode="KR" value="82">Korea South </option>
                                                    <option data-countryCode="KW" value="965">Kuwait </option>
                                                    <option data-countryCode="KG" value="996">Kyrgyzstan </option>
                                                    <option data-countryCode="LA" value="856">Laos </option>
                                                    <option data-countryCode="LV" value="371">Latvia </option>
                                                    <option data-countryCode="LB" value="961">Lebanon </option>
                                                    <option data-countryCode="LS" value="266">Lesotho </option>
                                                    <option data-countryCode="LR" value="231">Liberia </option>
                                                    <option data-countryCode="LY" value="218">Libya </option>
                                                    <option data-countryCode="LI" value="417">Liechtenstein </option>
                                                    <option data-countryCode="LT" value="370">Lithuania </option>
                                                    <option data-countryCode="LU" value="352">Luxembourg </option>
                                                    <option data-countryCode="MO" value="853">Macao </option>
                                                    <option data-countryCode="MK" value="389">Macedonia </option>
                                                    <option data-countryCode="MG" value="261">Madagascar </option>
                                                    <option data-countryCode="MW" value="265">Malawi </option>
                                                    <option data-countryCode="MY" value="60">Malaysia</option>
                                                    <option data-countryCode="MV" value="960">Maldives </option>
                                                    <option data-countryCode="ML" value="223">Mali </option>
                                                    <option data-countryCode="MT" value="356">Malta </option>
                                                    <option data-countryCode="MH" value="692">Marshall Islands </option>
                                                    <option data-countryCode="MQ" value="596">Martinique </option>
                                                    <option data-countryCode="MR" value="222">Mauritania </option>
                                                    <option data-countryCode="YT" value="269">Mayotte </option>
                                                    <option data-countryCode="MX" value="52">Mexico </option>
                                                    <option data-countryCode="FM" value="691">Micronesia</option>
                                                    <option data-countryCode="MD" value="373">Moldova </option>
                                                    <option data-countryCode="MC" value="377">Monaco </option>
                                                    <option data-countryCode="MN" value="976">Mongolia </option>
                                                    <option data-countryCode="MS" value="1664">Montserrat </option>
                                                    <option data-countryCode="MA" value="212">Morocco </option>
                                                    <option data-countryCode="MZ" value="258">Mozambique </option>
                                                    <option data-countryCode="MN" value="95">Myanmar </option>
                                                    <option data-countryCode="NA" value="264">Namibia </option>
                                                    <option data-countryCode="NR" value="674">Nauru </option>
                                                    <option data-countryCode="NP" value="977">Nepal</option>
                                                    <option data-countryCode="NL" value="31">Netherlands </option>
                                                    <option data-countryCode="NC" value="687">New Caledonia </option>
                                                    <option data-countryCode="NZ" value="64">New Zealand </option>
                                                    <option data-countryCode="NI" value="505">Nicaragua</option>
                                                    <option data-countryCode="NE" value="227">Niger </option>
                                                    <option data-countryCode="NG" value="234">Nigeria </option>
                                                    <option data-countryCode="NU" value="683">Niue </option>
                                                    <option data-countryCode="NF" value="672">Norfolk Islands</option>
                                                    <option data-countryCode="NP" value="670">Northern Marianas </option>
                                                    <option data-countryCode="NO" value="47">Norway </option>
                                                    <option data-countryCode="OM" value="968">Oman </option>
                                                    <option data-countryCode="PW" value="680">Palau </option>
                                                    <option data-countryCode="PA" value="507">Panama </option>
                                                    <option data-countryCode="PG" value="675">Papua New Guinea</option>
                                                    <option data-countryCode="PY" value="595">Paraguay </option>
                                                    <option data-countryCode="PE" value="51">Peru </option>
                                                    <option data-countryCode="PH" value="63">Philippines</option>
                                                    <option data-countryCode="PL" value="48">Poland </option>
                                                    <option data-countryCode="PT" value="351">Portugal </option>
                                                    <option data-countryCode="PR" value="1787">Puerto Rico</option>
                                                    <option data-countryCode="QA" value="974">Qatar </option>
                                                    <option data-countryCode="RE" value="262">Reunion</option>
                                                    <option data-countryCode="RO" value="40">Romania</option>
                                                    <option data-countryCode="RU" value="7">Russia </option>
                                                    <option data-countryCode="RW" value="250">Rwanda </option>
                                                    <option data-countryCode="SM" value="378">San Marino </option>
                                                    <option data-countryCode="ST" value="239">Sao Tome &amp; Principe
                                                    </option>
                                                    <option data-countryCode="SA" value="966">Saudi Arabia </option>
                                                    <option data-countryCode="SN" value="221">Senegal</option>
                                                    <option data-countryCode="CS" value="381">Serbia </option>
                                                    <option data-countryCode="SC" value="248">Seychelles </option>
                                                    <option data-countryCode="SL" value="232">Sierra Leone </option>
                                                    <option data-countryCode="SG" value="65">Singapore </option>
                                                    <option data-countryCode="SK" value="421">Slovak Republic</option>
                                                    <option data-countryCode="SI" value="386">Slovenia</option>
                                                    <option data-countryCode="SB" value="677">Solomon Islands</option>
                                                    <option data-countryCode="SO" value="252">Somalia </option>
                                                    <option data-countryCode="ZA" value="27">South Africa </option>
                                                    <option data-countryCode="ES" value="34">Spain</option>
                                                    <option data-countryCode="LK" value="94">Sri Lanka</option>
                                                    <option data-countryCode="SH" value="290">St. Helena </option>
                                                    <option data-countryCode="KN" value="1869">St. Kitts</option>
                                                    <option data-countryCode="SC" value="1758">St. Lucia </option>
                                                    <option data-countryCode="SD" value="249">Sudan </option>
                                                    <option data-countryCode="SR" value="597">Suriname </option>
                                                    <option data-countryCode="SZ" value="268">Swaziland </option>
                                                    <option data-countryCode="SE" value="46">Sweden </option>
                                                    <option data-countryCode="CH" value="41">Switzerland </option>
                                                    <option data-countryCode="SI" value="963">Syria </option>
                                                    <option data-countryCode="TW" value="886">Taiwan </option>
                                                    <option data-countryCode="TJ" value="7">Tajikstan</option>
                                                    <option data-countryCode="TH" value="66">Thailand</option>
                                                    <option data-countryCode="TG" value="228">Togo </option>
                                                    <option data-countryCode="TO" value="676">Tonga </option>
                                                    <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago
                                                    </option>
                                                    <option data-countryCode="TN" value="216">Tunisia </option>
                                                    <option data-countryCode="TR" value="90">Turkey</option>
                                                    <option data-countryCode="TM" value="7">Turkmenistan </option>
                                                    <option data-countryCode="TM" value="993">Turkmenistan </option>
                                                    <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands
                                                    </option>
                                                    <option data-countryCode="TV" value="688">Tuvalu </option>
                                                    <option data-countryCode="UG" value="256">Uganda </option>
                                                    {/* <!-- <option data-countryCode="GB" value="44">UK (+44)</option> --> */}
                                                    <option data-countryCode="UA" value="380">Ukraine </option>
                                                    <option data-countryCode="AE" value="971">United Arab Emirates
                                                    </option>
                                                    <option data-countryCode="UY" value="598">Uruguay </option>
                                                    {/* <!-- <option data-countryCode="US" value="1">USA (+1)</option> --> */}
                                                    <option data-countryCode="UZ" value="7">Uzbekistan</option>
                                                    <option data-countryCode="VU" value="678">Vanuatu </option>
                                                    <option data-countryCode="VA" value="379">Vatican City </option>
                                                    <option data-countryCode="VE" value="58">Venezuela </option>
                                                    <option data-countryCode="VN" value="84">Vietnam </option>
                                                    <option data-countryCode="VG" value="84">Virgin Islands - British
                                                    </option>
                                                    <option data-countryCode="VI" value="84">Virgin Islands - US
                                                    </option>
                                                    <option data-countryCode="WF" value="681">Wallis &amp; Futuna
                                                    </option>
                                                    <option data-countryCode="YE" value="969">Yemen (North)</option>
                                                    <option data-countryCode="YE" value="967">Yemen (South)</option>
                                                    <option data-countryCode="ZM" value="260">Zambia </option>
                                                    <option data-countryCode="ZW" value="263">Zimbabwe </option>

                                                </select>
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                                <p id="demo1"></p>



                                                {/* <script>
                                                                            var x_ip = "";
                                                                            function Func_a() {
                                                var x_ip = document.getElementById("Choose").value;
                                                                            const myArray = x_ip.split("@");
                                                                            console.log(myArray[1]);
                                                                            const select = document.getElementById('number');
                                                                            select.value = "+" + myArray[0];
                                                                            console.log(select.value);
                                            }
                                                                        </script> */}

                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>


                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <span>Project Scope, Description & Reference (optional)</span>
                                                <textarea class="form-control" name="refer" value={state.refer} onChange={changeHandler} cols="30" rows="6"
                                                    placeholder="Your message..."></textarea>
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <span>Any attachment (optional)</span>
                                                <input type="file" name="img" class="form-control" onChange={changeHandler}
                                                    accept=".doc,.docx, .pdf,.jpg,.png,.jpeg"
                                                    placeholder="Your Target Audience" />
                                                {/* <!--<div class="help-block with-errors"></div>--> */}
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12" style={{ marginTop: "30px" }}>

                                            <ReCAPTCHA
                                                ref={captch}
                                                sitekey={key}
                                                onChange={onChange}
                                            />
                                            {visible && <p style={{ color: 'red' }}>{ercapacha}</p>}
                                            {visible && <p style={{ color: 'green' }}>{status}</p>}
                                        </div>

                                        <div class="col-lg-12 col-md-12"
                                            style={{ textAlign: "center", marginTop: "50px", marginBottom: "50px" }}>

                                            <button type="submit" name="submit" class="theme-btn-s2"
                                                value="Submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                                <div id="msgSubmitTwo" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </>
    )
}

export default Req