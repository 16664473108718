import React from "react";
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import axios from "axios";
import { useRef } from "react";
import { Link } from "react-router-dom";

const Contact = () => {

    const [verified, setVerified] = useState('');
    const [ercapacha, setErcapacha] = useState('')
    const [status, setStatus] = useState('')
    const [visible, setIsVisible] = useState(true);
    const [visible1, setIsVisible1] = useState(true);
    const captch = useRef(null);

    const key = '6LfqGJ8gAAAAAOGYrU5mnv6cZxePqeJ_u85A3t5L';

    function onChanges() {
        setVerified(true);
    }

    const initalState = {
        names: "",
        email: "",
        message: "",
        phone: ""
    };

    const [state, setState] = useState(initalState);
    const { names, email, message, phone } = state;

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        if (verified === true) {
            axios
                .post("https://www.gurusoftware.in/contact.php", {
                    names,
                    email,
                    message,
                    phone
                })
                .then(function (data) {
                    console.log(data.data);
                    if (data.data.message === "radha") {
                        captch.current.reset()
                        setIsVisible1(true);
                        setState({ names: "", email: "", message: "", phone: "" });
                        setStatus("Message sent successfully. We will connect you soon")
                        setTimeout(function () {
                            setIsVisible1(false);
                        }, 3000);
                    }
                })

        } else {
            setErcapacha("Please Verify CAPTCHA")
            setIsVisible(true);
            setTimeout(function () {
                setIsVisible(false);
            }, 3000);
            console.log("fail");

        }

    };
    return (
        <>
            <Helmet>
                <title>GuruSoftware: Your IT Services Partner</title>
                <meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

                <meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
                <meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
                <meta property="og:url" content="https://gurusoftware.in/" />
                <meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
                <meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

            </Helmet>
            <section class="wpo-page-title">
                <div class="container">
                    <div class="row">
                        <div class="col col-xs-12">
                            <div class="wpo-breadcumb-wrap">
                                <h2>Contact Us</h2>
                                <ol class="wpo-breadcumb-wrap">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Contact</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="wpo-contact-pg-section section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col col-lg-10 offset-lg-1">
                            <div class="office-info">
                                <div class="row">

                                    <div class="col col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="office-info-item">
                                            <div class="office-info-icon">
                                                <div class="icon">
                                                    <i class="fi flaticon-009-telephone"></i>
                                                </div>
                                            </div>
                                            <div class="office-info-text">
                                                <h2 style={{ color: "#fff" }}>Call Now</h2>
                                                <p style={{ color: "#fff" }}>+91 9409 354 811</p>
                                                <p style={{ color: "#fff" }}>+91 8000 055 915</p>
                                                {/* <p>&nbsp;</p> */}
                                                <p>&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="office-info-item">
                                            <div class="office-info-icon">
                                                <div class="icon">
                                                    <i class="fi flaticon-011-maps-and-flags"></i>
                                                </div>
                                            </div>
                                            <div class="office-info-text">
                                                <h2 style={{ color: "#fff" }}>Address</h2>
                                                <p style={{ color: "#fff" }}>
                                                    510, Kasturi Pride, OPP. Torrent Power, SP Ring Road, Nikol, Ahmedabad, Gujarat
                                                    382350.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col col-xl-4 col-lg-6 col-md-6 col-12">
                                        <div class="office-info-item">
                                            <div class="office-info-icon">
                                                <div class="icon">
                                                    <i class="fi flaticon-037-email-1"></i>
                                                </div>
                                            </div>
                                            <div class="office-info-text">
                                                <h2 style={{ color: "#fff" }}>Email Us</h2>
                                                <p style={{ color: "#fff" }}>contact@gurusoftware.in</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="wpo-contact-title">
                                <h2 >Have Any Question?</h2>
                                <p style={{ color: "#2A6175" }}>Let's Connect and Transform Your IT Needs into Solutions</p>
                            </div>
                            <div class="wpo-contact-form-area">
                                <form onSubmit={handleSubmit} class="contact-validation-active" id="contact-form-main">
                                    <div>
                                        <input type="text" class="form-control" value={state.names} onChange={changeHandler} name="names" id="name" placeholder="Name" required />
                                    </div>

                                    <div>
                                        <input type="text" class="form-control" value={state.phone} onChange={changeHandler} name="phone" id="phone" placeholder="Phone" required />
                                    </div>
                                    <div class="fullwidth">
                                        <input type="email" class="form-control" style={{ width: "100%" }} value={state.email} onChange={changeHandler} name="email" id="email" placeholder="Email" required />
                                    </div>
                                    {/* <div>

                                    </div> */}
                                    <div class="fullwidth">
                                        <textarea class="form-control" name="message" value={state.message} onChange={changeHandler} id="note" placeholder="Message..."></textarea>
                                    </div>
                                    <ReCAPTCHA
                                        ref={captch}
                                        sitekey={key}
                                        onChange={onChanges}
                                    />
                                    {visible && <p style={{ color: 'red' }}>{ercapacha}</p>}
                                    {visible1 && <p style={{ color: 'green' }}>{status}</p>}
                                    <div class="submit-area">
                                        <button type="submit" class="theme-btn-s2">Get in Touch</button>
                                        <div id="loader">
                                            <i class="ti-reload"></i>
                                        </div>
                                    </div>
                                    <div class="clearfix error-handling-messages">
                                        <div id="success">Thank you</div>
                                        <div id="error"> Error occurred while sending email. Please try again later. </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="wpo-contact-map-section">
                    <h2 class="hidden">Contact map</h2>
                    <div class="wpo-contact-map">

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4119001654617!2d72.6773799760439!3d23.045355915476186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e86fdff8ffae3%3A0xea279ce2a7e45376!2sGuru%20Software!5e0!3m2!1sen!2sin!4v1693649873741!5m2!1sen!2sin"
                            width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </section>
            </section>

        </>
    )
}

export default Contact