import React from "react";
import { Helmet } from 'react-helmet-async';import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Home = () => {


      return (
        <>
            <Helmet>
               <title>GuruSoftware: Your IT Services Partner</title>
<meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

<meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:url" content="https://gurusoftware.in/" />
<meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:width" content="500" />
<meta property="og:image:height" content="500" />
<meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />
             
            </Helmet>
            <section class="static-hero" style={{paddingBottom:"130px"}}>
                <div class="hero-container">
                    <div class="hero-inner">
                        <div class="container">
                            <div class="hero-text-wrap">
                                <div data-swiper-parallax="300" class="sub-title">
                                    <span>LEADING GLOBAL TECHNOLOGY PROVIDER</span>
                                </div>
                                <div data-swiper-parallax="300" class="slide-title">
                                    <h2>Empowering Businesses Through Innovative IT Solutions.</h2>
                                </div>
                                <div data-swiper-parallax="400" class="slide-text">
                                    <p>We are leading technology solutions providing company all over the world.
                                    </p>
                                </div>
                                <div class="clearfix"></div>
                                <div data-swiper-parallax="500" class="slide-btns">
                                    <Link to="/about" class="theme-btn-s2">About Us</Link>
                                </div>
                            </div>
                            <div class="lawyer-pic">
                                <img src="assets/images/slider/1.png" alt="loading" />
                                <div class="lawyer-shape"></div>
                                <div class="exprience">
                                    {/* <div class="exprience-left">
                                        <h4>18</h4>
                                    </div>
                                    <div class="exprience-right">
                                        <h4>Country</h4>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="wpo-about-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">
                        <span>About Us</span>
                        <h2 >Get to Know Guru Software</h2>
                    </div>
                    <div class="wpo-about-section-wrapper">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12 col-12">
                                <div class="wpo-about-content">
                                    <h2>Transform your vision into reality with our expertise.</h2>
                                    <div class="wpo-about-content-inner">
                                        <p>For over a decade, we have been delivering outstanding services and solutions,
                                            consistently achieving complete customer satisfaction. Our extensive clientele base
                                            speaks volumes about our commitment to excellence. Once you've experienced working with
                                            us, it becomes clear that finding such quality and professionalism elsewhere is nearly
                                            impossible.</p>
                                        <p> Our unwavering dedication and expertise have established us as a highly reputable entity
                                            in the web development sector. Our guiding principle is inclusive development paired
                                            with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing
                                            growth, becoming a paramount service development entity capable of catering to every
                                            customer's diverse needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12 col-12">
                                <div class="wpo-about-img">
                                    <img src="assets/images/about3.jpg" alt="loading" />
                                    <div class="about-img-inner">
                                        <img src="assets/images/about2.jpg" alt="loading" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="visible-text">
                            <h2>About</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="wpo-section-title">
                                <span style={{ color: "#d2ae6d" }}>Our Services</span>
                                <h2>Transforming Ideas into Reality</h2>
                            </div>
                        </div>
                    </div>

                    <div class="wpo-practice-wrap" style={{ marginBottom: "20px" }}>
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-laptop-code"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/web-design-and-development-services">WEBSITE DEVELOPMENT</Link></h2>
                                        <p>Website development encompasses the creation and upkeep of websites, focusing on making
                                            them visually appealing, fast, and providing a seamless user experience.</p>

                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/web-design-and-development-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-mobile-alt"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/mobile-application-services">MOBILE APP DEVELOPMENT</Link></h2>
                                        <p>Mobile app development is the process of creating applications specifically designed for
                                            mobile devices. This intricate process entails the development of software tailored for
                                            small, wireless computing devices.</p>


                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/mobile-application-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-cog"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/custom-software-services">CUSTOMIZED SOFTWARE </Link></h2>
                                        <p>Customized software refers to computer programs or applications that are specifically
                                            designed and developed to meet the unique needs and requirements of a particular
                                            business or organization.
                                        </p>
                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/custom-software-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <i class="fas fa-rocket"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/digital-marketing-services">DIGITAL MARKETING</Link></h2>
                                        <p>Digital marketing is a strategic approach that utilizes various online channels to
                                            captivate, interact with, and convert customers on the internet.</p>
                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/digital-marketing-services" class="theme-btn-s2">View More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section class="wpo-terms-section section-padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="wpo-section-title">
                                <span>Frequently Ask Questions</span>
                                <h2 >Unlocking Answers to Your Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="wpo-faq-section">

                            <div class="row">
                                <div class="col-lg-8 col-md-6 col-12">
                                    <div class="accordion" id="accordionExample">
                                       
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button " type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How long does it typically take to complete a web development project?
                                                </button>
                                            </h3>
                                            <div id="collapseTwo" class="accordion-collapse collapse show"  aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p style={{ color: "black" }}>The timeline for a web development project varies depending on its complexity and the specific requirements. We will provide you with a project timeline during our initial consultation</p>
                                                </div>
                                                {/* <div class="slide-btns mb-3"
                                                    style={{ marginLeft: "10px", padding: "6px 25px", fontSize: "10px" }}>
                                                    <Link to="/mobile-application-services" class="theme-btn-s2">Know More</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                    What is digital marketing, and how can it benefit my business?
                                                </button>
                                            </h3>
                                            <div id="collapseThree" class="accordion-collapse collapse"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p style={{ color: "black" }}>Digital marketing involves promoting your products or services online. It includes strategies such as search engine optimization (SEO), social media marketing, email marketing, and paid advertising. These techniques help increase your online visibility, attract more customers, and boost your revenue.</p>
                                                </div>
                                                {/* <div class="slide-btns mb-3"
                                                    style={{ marginLeft: "10px", padding: "6px 25px", fontSize: "10px" }}>
                                                    <Link to="/custom-software-services" class="theme-btn-s2">Know More</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                     How can SEO writing improve website's performance?
                                                </button>
                                            </h3>
                                            <div id="collapseOne" class="accordion-collapse collapse "
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p style={{ color: "black" }}>SEO writing involves creating content that is optimized for search engines. By incorporating relevant keywords and following best practices, your website will rank higher in search engine results pages (SERPs). This leads to increased organic traffic and improved online visibility.</p>
                                                </div>
                                                {/* <div class="slide-btns mb-3"
                                                    style={{ marginLeft: "10px", padding: "6px 25px", fontSize: "10px" }}>
                                                    <Link to="/web-design-and-development-services" class="theme-btn-s2">Know More</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h3 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseFour" aria-expanded="false"
                                                    aria-controls="collapseFour">
                                                    How can I get in touch with your team to discuss my project?
                                                </button>
                                            </h3>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p style={{ color: "black" }}>You can contact us through our website, email, or phone. We're always available to answer your questions and start a conversation about your IT needs.</p>
                                                </div>
                                                {/* <div class="slide-btns mb-3"
                                                    style={{ marginLeft: "10px", padding: "6px 25px", fontSize: "10px" }}>
                                                    <Link to="/digital-marketing_service" class="theme-btn-s2">Know More</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="wpo-terms-wrap">
                                        <div class="wpo-terms-img">
                                            <img src="assets/images/terms/terms.jpg" alt="loading" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            {/* <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">
                        <span>How Can We Help You</span>
                        <h2>Legal Practice Areas</h2>
                    </div>
                    <div class="wpo-practice-wrap">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="wpo-practice-item" style={{ background: "none", border: "none" }}>
                                    <div class="wpo-practice-icon">
                                        <h3 class="digit">3+</h3>
                                        {/* <i class="fas fa-star-half-alt"></i> 
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h4 class="sentmile">YEARS EXPERIENCE</h4>
                                        {/* <p>We can help you when you gitpsum dolor sitwere amet, constur adicing elit.
                                            Ultrices cursus sitting scelsl.</p> */}
            {/* <h2 class="sentmile">This is an <h3 class="digit">example</h3> of nesting heading tags.</h2> 

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12" >
                                <div class="wpo-practice-item" style={{ background: "none", border: "none" }}>
                                    <div class="wpo-practice-icon">
                                        <i class="fi flaticon-013-planning"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="practice-single.html">Business Law</Link></h2>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="wpo-practice-item" style={{ background: "none", border: "none" }}>
                                    <div class="wpo-practice-icon">
                                        <i class="fi flaticon-014-learning"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="practice-single.html">Education Law</Link></h2>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="wpo-practice-item" style={{ background: "none", border: "none" }}>
                                    <div class="wpo-practice-icon">
                                        <i class="fi flaticon-015-patient"></i>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="practice-single.html">Personal Injury</Link></h2>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section class="wpo-fun-fact-section" style={{ marginBottom: "-120px" }}>
                <div class="container">
                    <div class="row">
                        <div class="col col-xs-12">
                            <div class="wpo-fun-fact-grids clearfix">
                                <div class="grid">
                                    <div class="info">
                                        <h3><span class="odometer" data-count="235">3</span>+</h3>
                                        <p>Years Experience</p>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="info">
                                        <h3><span class="odometer" data-count="95">90</span>+</h3>
                                        <p>Website Built</p>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="info">
                                        <h3><span class="odometer" data-count="120">18</span>+</h3>
                                        <p>Country</p>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="info">
                                        <h3><span class="odometer" data-count="15">135</span>+</h3>
                                        <p>Project Done</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section class="wpo-blog-section section-padding">
                <div class="container">
                    <div class="wpo-blog-wrap">
                        <div class="wpo-section-title-s2">
                            <span style={{color:"#d2ae6d"}}>Our Blog</span>
                            <h2 >Latest News Update</h2>
                        </div>
                        <div class="wpo-blog-items">
                            <div class="row">
                                <div class="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div class="wpo-blog-item">
                                        <div class="wpo-blog-img">
                                            <img src="assets/images/blog-details/b1.jpg" alt="loading"/>
                                        </div>
                                        <div class="wpo-blog-content">
                                            {/* <ul>
                                                <li>Sep 03, 2021</li>
                                                <li><Link to="blog-single.html">Anne William</Link></li>
                                                <li>1 comment</li>
                                            </ul> */}
                                            <h2><Link to="/the-importance-of-cybersecurity-in-modern-software-development">The Importance of Cybersecurity in Modern Software Development</Link>
                                            </h2>
                                            <Link class="b-btn" to="/the-importance-of-cybersecurity-in-modern-software-development">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div class="wpo-blog-item">
                                        <div class="wpo-blog-img">
                                            <img src="assets/images/blog-details/b2.jpg" alt="loading"/>
                                        </div>
                                        <div class="wpo-blog-content">
                                            {/* <ul>
                                                <li>Jan 15, 2023</li>
                                                <li><Link to="blog-single.html">Ken William</Link></li>
                                                <li>5 comment</li>
                                            </ul> */}
                                            <h2><Link to="/embracing-agile-accelerating-software-development-and-delivery">Embracing Agile: Accelerating Software Development and Delivery</Link></h2>
                                            <Link class="b-btn" to="/embracing-agile-accelerating-software-development-and-delivery">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div class="wpo-blog-item">
                                        <div class="wpo-blog-img">
                                            <img src="assets/images/blog-details/b8.jpg" alt="loading"/>
                                        </div>
                                        <div class="wpo-blog-content">
                                            {/* <ul>
                                                <li>Feb 22, 2021</li>
                                                <li><Link to="blog-single.html">Anne Aliza</Link></li>
                                                <li>2 comment</li>
                                            </ul> */}
                                            <h2><Link to="/the-power-of-ai-and-machine-learning-in-software-applications">The Power of AI and Machine Learning in Software Applications</Link></h2>
                                            <Link class="b-btn" to="/the-power-of-ai-and-machine-learning-in-software-applications">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>


            {/* <section class="wpo-testimonials-section section-padding">
                <div class="container">
                    <div class="wpo-testimonials-wrap">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-12">
                                <div class="wpo-testimonials-active owl-carousel owl-loaded owl-drag">




                                    <div class="owl-stage-outer"><div class="owl-stage" style={{ transform: "translate3d(-4050px, 0px, 0px)", transition: "all 0.3s ease 0s", width: "6480px" }}><div class="owl-item cloned" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-2.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>David Miller</h5>
                                                <span>Designer UK</span>
                                                <span>Designer UK</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item cloned" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-3.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>David Miller</h5>
                                                <span>Designer UK</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-1.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>Milani Harverd</h5>
                                                <span>CEO Of GRK Egency</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-3.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>Aliza Anney</h5>
                                                <span>Model Belarus</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-2.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>David Miller</h5>
                                                <span>Designer UK</span>
                                                <span>Designer UK</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item active" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-3.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>David Miller</h5>
                                                <span>Designer UK</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item cloned" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-1.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>Milani Harverd</h5>
                                                <span>CEO Of GRK Egency</span>
                                            </div>
                                        </div>
                                    </div></div><div class="owl-item cloned" style={{ width: "800px", marginRight: "10px" }}><div class="wpo-testimonials-item">
                                        <p>“Elementum viverra tortor rhoncus nunc. Nunc proin lacinia interdum nulla fusce
                                            nam. Sagittis dolor hendrerit donec in eu, facilisis lobortis. Hendr laoret
                                            pretium veslum egestas.” </p>
                                        <div class="wpo-testimonial-info">
                                            <div class="wpo-testimonial-info-img">
                                                <img src="assets/images/testimonial/img-3.jpg" alt="loading" />
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>Aliza Anney</h5>
                                                <span>Model Belarus</span>
                                            </div>
                                        </div>
                                    </div></div></div></div><div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div><div class="owl-dots"><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot"><span></span></button><button role="button" class="owl-dot active"><span></span></button></div></div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}

            {/* <section class="wpo-blog-pg-section blog-pg-left-sidebar section-padding">
                <div class="container">
                    <div class="row">
                        <div class="wpo-blog-content">
                            <div class="wpo-section-title-s2">
                                <span style={{ color: "#D1AE6C" }}>ARTICLE</span>
                                <h2 style={{ color: "#204653" }}>Read Our Blog To Get All Recent Tech <b>News</b> </h2>

                            </div>

                            <div class="row">
                                <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                    <div class="post format-video format-standard-image" style={{ padding: "10px" }}>
                                        <div class="entry-media video-holder">
                                            <img src="assets/images/blog-details/b1.jpg" alt="loading" />
                                        </div>
                                        <div class="entry-meta">

                                        </div>
                                        <div class="entry-details">
                                            <div style={{ height: "145px" }}>
                                                <h3><Link to="/the-importance-of-cybersecurity-in-modern-software-development">The Importance of Cybersecurity in Modern Software
                                                    Development</Link></h3>
                                            </div>
                                            <p class="truncate-text" style={{ color: "#fff" }}>In an era of increasing cyber threats,
                                                this blog explores the critical role of cybersecurity in software development. We
                                                delve into best practices, tools, and strategies to safeguard your software and data
                                                from potential breaches, helping you protect your business and customers.</p>
                                            <div data-swiper-parallax="500" class="slide-btns">
                                                <Link to="/the-importance-of-cybersecurity-in-modern-software-development" class="theme-btn-s2">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                    <div class="post format-video format-standard-image" style={{ padding: "10px" }}>
                                        <div class="entry-media video-holder">
                                            <img src="assets/images/blog-details/b2.jpg" alt="loading" />
                                        </div>
                                        <div class="entry-meta">

                                        </div>
                                        <div class="entry-details">
                                            <div style={{ height: "145px" }}>
                                                <h3><Link to="/embracing-agile-accelerating-software-development-and-delivery">Embracing Agile: Accelerating Software Development and Delivery</Link></h3>
                                            </div>
                                            <p class="truncate-text" style={{ color: "#fff" }}>Discover how adopting Agile methodologies
                                                can revolutionize your software development process. This blog explores the
                                                principles of Agile, its benefits, and practical implementation strategies to
                                                streamline your development cycles, increase collaboration, and deliver high-quality
                                                software faster.</p>
                                            <div data-swiper-parallax="500" class="slide-btns">
                                                <Link to="/embracing-agile-accelerating-software-development-and-delivery" class="theme-btn-s2">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                    <div class="post format-video format-standard-image" style={{ padding: "10px" }}>
                                        <div class="entry-media video-holder">
                                            <img src="assets/images/blog-details/b8.jpg" alt="loading" />
                                        </div>
                                        <div class="entry-meta">

                                        </div>
                                        <div class="entry-details">
                                            <div style={{ height: "145px" }}>
                                                <h3><Link to="/the-power-of-ai-and-machine-learning-in-software-applications">The Power of AI and Machine Learning in Software Applications</Link></h3>
                                            </div>
                                            <p class="truncate-text" style={{ color: "#fff" }}>Artificial Intelligence (AI) and Machine
                                                Learning (ML) are reshaping the software landscape. This blog discusses real-world
                                                applications of AI and ML in software development, highlighting how these
                                                technologies can enhance user experiences, automate tasks, and provide valuable
                                                insights.</p>
                                            <div data-swiper-parallax="500" class="slide-btns">
                                                <Link to="/the-power-of-ai-and-machine-learning-in-software-applications" class="theme-btn-s2">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section class="wpo-features-section">
                <div class="container">
                    <div class="wpo-features-wrap">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6 custom-grid col-12">
                                <div class="features-item">
                                    <div class="features-icon"><i class="fi flaticon-005-trophy"></i></div>
                                    <div class="features-text">
                                        <span>10 Years</span>
                                        <h4>Winning Award</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 custom-grid col-12">
                                <div class="features-item">
                                    <div class="features-icon"><i class="fi flaticon-008-advocate"></i></div>
                                    <div class="features-text">
                                        <span>120+</span>
                                        <h4>Skilled Attorneys</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 custom-grid col-12">
                                <div class="features-item">
                                    <div class="features-icon"><i class="fi flaticon-006-law"></i></div>
                                    <div class="features-text">
                                        <span>99%</span>
                                        <h4>Case Win</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 custom-grid col-12">
                                <div class="features-item">
                                    <div class="features-icon"><i class="fi flaticon-008-advocate"></i></div>
                                    <div class="features-text">
                                        <span>120+</span>
                                        <h4>Skilled Attorneys</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


        </>
    )
}

export default Home