import React from "react";
import { Helmet } from 'react-helmet-async';import { Link } from "react-router-dom";

const DigService = () => {
      return (
        <>
            <Helmet>
               <title>GuruSoftware: Your IT Services Partner</title>
<meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

<meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:url" content="https://gurusoftware.in/" />
<meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:width" content="500" />
<meta property="og:image:height" content="500" />
<meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />
             
            </Helmet>
            <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">

                        <h2>DIGITAL MARKETING</h2>
                    </div>
                    <div class="wpo-practice-wrap" style={{ marginBottom: "20px" }}>
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/seo.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/seo">SEO SERVICES</Link></h2>
                                        <p>Elevate your online visibility and organic traffic with our expert SEO services, tailored to boost your website's search engine rankings and drive sustainable growth.</p>
                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/seo" class="theme-btn-s2">Read More</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/digital-marketing.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/digital-marketing">DIGITAL MARKETING</Link></h2>
                                        <p>Navigate the digital landscape with confidence. Our comprehensive digital marketing strategies harness the power of online platforms to connect, engage, and convert your target audience.</p>

                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/digital-marketing" class="theme-btn-s2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/brand.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/brand-consulting">BRAND CONSULTING</Link></h2>
                                        <p>Uncover your brand's true potential with our brand consulting services. We provide expert guidance to define, refine, and amplify your brand identity, ensuring a lasting impression.</p>

                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/brand-consulting" class="theme-btn-s2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/sem.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/search-engine-marketing">SEARCH ENGINE MARKETING</Link></h2>
                                        <p>Maximize your online reach and ROI through effective search engine marketing. Our strategies combine paid advertising and organic efforts to dominate search engine results.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/search-engine-marketing" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/social-media.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/social-media-marketing">SOCIAL MEDIA MARKETING</Link></h2>
                                        <p>Harness the influence of social media. Our social media marketing campaigns are designed to engage your audience, increase brand awareness, and drive conversions.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/social-media-marketing" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/pay-per-click.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/pay-per-click-management">PAY PER CLICK MANAGEMENT</Link></h2>
                                        <p>Get immediate results with precision. Our PPC management services optimize your ad spend, delivering targeted traffic and measurable returns on investment.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/pay-per-click-management" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/copywriting.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/seo-copywrite">SEO COPYWRITING</Link></h2>
                                        <p>Craft compelling content that ranks and resonates. Our team creates high-quality content to enhance your website's performance in search engines.</p>

                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/seo-copywrite" class="theme-btn-s2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/ui.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/ui-ux-design">UI/UX DESIGN</Link></h2>
                                        <p>Elevate user experiences with intuitive and visually appealing UI/UX design.We blend creativity and usability to create digital interfaces that captivate and convert.</p>


                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/ui-ux-design" class="theme-btn-s2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/design.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/logo-design">LOGO DESIGN</Link></h2>
                                        <p>Make a memorable first impression with a unique logo. Our logo design services are tailored to reflect your brand's identity and leave a lasting mark.</p>


                                        <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                            <Link to="/logo-design" class="theme-btn-s2">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                    <img src="assets/images/logo/brochure.png" style={{height:"60px",width:"60px"}} class="slogo" alt="loading"/>
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/broucher-design">BROCHURE MARKETING</Link></h2>
                                        <p>Showcase your products or services in style. Our brochure marketing solutions combine compelling design and persuasive content to leave a lasting impact on your audience.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/broucher-design" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DigService