import React from "react";
import { Helmet } from 'react-helmet-async';import { Link } from "react-router-dom";

const MobService = () => {
    return (


        <>

            <section class="wpo-practice-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">

                        <h2>MOBILE APP DEVELOPMENT </h2>
                    </div>
                    <div class="wpo-practice-wrap" style={{ marginBottom: "20px" }}>
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/mobile-development.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/mobile-app-development">MOBILE APP DEVELOPMENT</Link></h2>
                                        <p>We craft customized mobile applications that engage users and enhance your brand's mobile presence.</p>

                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/mobile-app-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/hybrid-app.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/hybrid-app-development">HYBRID APP DEVELOPMENT</Link></h2>
                                        <p>Harnessing the power of cross-platform technology, we create versatile apps that work seamlessly on various devices.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/hybrid-app-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-5 col-12" style={{ marginBottom: "30px" }}>
                                <div class="wpo-practice-item">
                                    <div class="wpo-practice-icon">
                                        <img src="assets/images/logo/booking.png" style={{ height: "60px", width: "60px" }} class="slogo" alt="loading" />
                                    </div>
                                    <div class="wpo-practice-content">
                                        <h2><Link to="/native-app-development">NATIVE APP DEVELOPMENT</Link></h2>
                                        <p>We specialize in building native apps for iOS and Android, delivering high performance and a seamless user experience.</p>
                                    </div>
                                    <div data-swiper-parallax="500" class="slide-btns" style={{ marginTop: "40px" }}>
                                        <Link to="/native-app-development" class="theme-btn-s2">Read More</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MobService