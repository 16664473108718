import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'; import { Link } from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Header from "./pages/header";
import Footer from "./pages/footer";
import About from "./pages/about";
import Contact from "./pages/contact";
import SEO from "./pages/seo_services";
import CrmDevolopment from "./pages/crm_development";
import DigitalMarketing from "./pages/digital_markrting";
import BrandConsulting from "./pages/brand_consulting";
import SearchEngineMarketing from "./pages/search_engine";
import SocialMedia from "./pages/social_media";
import PayPer from "./pages/pay_per";
import SeoCopywrite from "./pages/seo_copywrite";
import WebDesign from "./pages/website_design";
import WebDevolop from "./pages/web_devolopment";
import SoftDevelop from "./pages/software_development";
import EduWebPort from "./pages/edu_web_prt";
import RestWebPort from "./pages/resta_web_poratal";
import HealthPro from "./pages/helth_care";
import Travel from "./pages/travel_portal";
import RealEstatePort from "./pages/real_estate";
import EcomPort from "./pages/ecomPort";
import MobileApp from "./pages/mobile_app";
import HybridApp from "./pages/hybrid_app";
import NativeApp from "./pages/native";
import UiUx from "./pages/ui_ux";
import Logo from "./pages/logo";
import BrouDesign from "./pages/brouchre";
import HrPayRoll from "./pages/hr_payroll";
import Jewelery from "./pages/jewelery";
import RealSoft from "./pages/real_soft";
import Inventory from "./pages/inventory";
import Hospital from "./pages/hospital";
import School from "./pages/school";
import WebDeService from "./pages/webMain";
import MobService from "./pages/mobile_services";
import CusService from "./pages/custom_service";
import DigService from "./pages/digital_service";
import CrmSoftware from "./pages/crm_software";
import Blog from "./pages/blog";
import Blog1 from "./pages/b1";
import Blog2 from "./pages/b2";
import Blog3 from "./pages/b3";
import Req from "./pages/requirement";
import ScrollToTop from "./scroll";
import Services from "./pages/services";





function App() {

  return (
    <HelmetProvider>
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>

          {/* <Switch> */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/requirement" element={<Req />} />
          <Route exact path="/services" element={<Services />} />

          {/* digital-marketing */}
          <Route exact path="/seo" element={<SEO />} />
          <Route exact path="/digital-marketing" element={<DigitalMarketing />} />
          <Route exact path="/brand-consulting" element={<BrandConsulting />} />
          <Route exact path="/search-engine-marketing" element={<SearchEngineMarketing />} />
          <Route exact path="/social-media-marketing" element={<SocialMedia />} />
          <Route exact path="/pay-per-click-management" element={<PayPer />} />
          <Route exact path="/seo-copywrite" element={<SeoCopywrite />} />
          <Route exact path="/ui-ux-design" element={<UiUx />} />
          <Route exact path="/logo-design" element={<Logo />} />
          <Route exact path="/broucher-design" element={<BrouDesign />} />

          {/* website-design */}
          <Route exact path="/website-design" element={<WebDesign />} />
          <Route exact path="/website-development" element={<WebDevolop />} />

          {/* custom_service */}
          <Route exact path="/crm-development" element={<CrmDevolopment />} />
          <Route exact path="/software-development" element={<SoftDevelop />} />
          <Route exact path="/education-web-portal-development" element={<EduWebPort />} />
          <Route exact path="/restaurant-web-portal-development" element={<RestWebPort />} />
          <Route exact path="/health-care-portal-development" element={<HealthPro />} />
          <Route exact path="/travel-portal-development" element={<Travel />} />
          <Route exact path="/real-estate-portal-development" element={<RealEstatePort />} />
          <Route exact path="/ecommerce-portal-development" element={<EcomPort />} />

          {/* mobile_app */}
          <Route exact path="/mobile-app-development" element={<MobileApp />} />
          <Route exact path="/hybrid-app-development" element={<HybridApp />} />
          <Route exact path="/native-app-development" element={<NativeApp />} />

          {/* software */}
          <Route exact path="/hr-and-pay-roll-management-software" element={<HrPayRoll />} />
          <Route exact path="/jewelery-software" element={<Jewelery />} />
          <Route exact path="/crm-software" element={<CrmSoftware />} />
          <Route exact path="/real-estate-software" element={<RealSoft />} />
          <Route exact path="/inventory-management-software" element={<Inventory />} />
          <Route exact path="/hospital-management-software" element={<Hospital />} />
          <Route exact path="/school-management-software" element={<School />} />

          {/* Blogs */}
          <Route exact path="/the-importance-of-cybersecurity-in-modern-software-development" element={<Blog1 />} />
          <Route exact path="/embracing-agile-accelerating-software-development-and-delivery" element={<Blog2 />} />
          <Route exact path="/the-power-of-ai-and-machine-learning-in-software-applications" element={<Blog3 />} />

          {/* //main services pages */}
          <Route exact path="/web-design-and-development-services" element={<WebDeService />} />
          <Route exact path="/mobile-application-services" element={<MobService />} />
          <Route exact path="/custom-software-services" element={<CusService />} />
          <Route exact path="/digital-marketing-services" element={<DigService />} />

        </Routes >

        {/* </Switch> */}
        <Footer />
      </Router>
    </HelmetProvider>

  );
}

export default App;
