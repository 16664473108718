import React from "react";
import { Helmet } from 'react-helmet-async';import { Link } from "react-router-dom";

const About = () => {
      return (
        <>
            <Helmet>
               <title>GuruSoftware: Your IT Services Partner</title>
<meta name="description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />

<meta property="og:title" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:site_name" content="GuruSoftware: Your IT Services Partner" />
<meta property="og:url" content="https://gurusoftware.in/" />
<meta property="og:image" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:url" content="https://gurusoftware.in/assets/images/favicon.png" />
<meta property="og:image:width" content="500" />
<meta property="og:image:height" content="500" />
<meta property="og:description" content="For over a decade, we have been delivering outstanding IT services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible. Our unwavering dedication and expertise have established us as a highly reputable entity in the IT services sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs." />
             
            </Helmet>

            <section class="wpo-page-title">
                <div class="container">
                    <div class="row">
                        <div class="col col-xs-12">
                            <div class="wpo-breadcumb-wrap">
                                <h2>About Us</h2>
                                <ol class="wpo-breadcumb-wrap">
                                    <li><Link to="/">Home</Link></li>
                                    <li>About</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end page-title --> */}

            <section class="wpo-about-section-s3 section-padding">
                <div class="container">
                    <div class="wpo-section-title-s2">
                        <span>About Us</span>
                        <h2 >Get to know Guru Software</h2>
                    </div>
                    <div class="wpo-about-section-wrapper ">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12 col-12">
                                <div class="wpo-about-content">
                                    <h1 style={{color:"white"}}>Transform your vision into reality with our expertise.</h1>
                                    <div class="wpo-about-content-inner">
                                        <p>For over a decade, we have been delivering outstanding services and solutions,
                                            consistently achieving complete customer satisfaction. Our extensive clientele base
                                            speaks volumes about our commitment to excellence. Once you've experienced working with
                                            us, it becomes clear that finding such quality and professionalism elsewhere is nearly
                                            impossible.</p>
                                        <p>Our unwavering dedication and expertise have established us as a highly reputable entity
                                            in the web development sector. Our guiding principle is inclusive development paired
                                            with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing
                                            growth, becoming a paramount service development entity capable of catering to every
                                            customer's diverse needs. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12 col-12">
                                <div class="wpo-about-img">
                                    <img src="assets/images/about3.jpg" alt="loading" />
                                    <div class="about-img-inner">
                                        <img src="assets/images/about2.jpg" alt="loading" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="visible-text">
                            <h2>About</h2>
                        </div>
                    </div>

                    <div class="office-info" style={{ marginTop: "70px" }}>
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-12">
                                <div class="abt"
                                    style={{ background: "rgb(33 70 83)", textAlign: "justify", padding: "30px", minHeight: "270px", border: "1px solid #D1AE6C" }}>
                                    <div class="office-info-item">
                                        {/* <!-- <div class="office-info-icon">
                            <div class="icon">
                                <i class="fi flaticon-011-maps-and-flags"></i>
                            </div>
                        </div> --> */}
                                        <div class="office-info-text">
                                            <h2 style={{ color: "#D1AE6C", textAlign: "center" }}>Our Vision</h2>
                                            <p style={{ marginTop: "20px" }}>Our vision is to lead the IT industry and give the present
                                                generation a better and
                                                technorich future by providing exceptional services and effective web and software
                                                solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-12">
                                <div class="abt"
                                    style={{ background: "rgb(33 70 83)", textAlign: "justify", padding: "30px", minHeight: "270px", border: "1px solid #D1AE6C" }}>
                                    <div class="office-info-item">
                                        {/* <!-- <div class="office-info-icon">
                            <div class="icon">
                                <i class="fi flaticon-037-email-1"></i>
                            </div>
                        </div> --> */}
                                        <div class="office-info-text">
                                            <h2 style={{ color: "#D1AE6C", textAlign: "center" }}>Our Mission</h2>
                                            <p style={{ marginTop: "20px" }}>Our mission is crystal clear: we aim to deliver cutting-edge
                                                software solutions that embody excellence and align with enterprise compliance
                                                standards.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section class="wpo-practice-section-s3" >
                <div class="container">
                    <div class="wpo-section-title-s2">
                       <span style={{color:"#D1AE6C"}}>About Us</span> 
                        <h2>What makes us different?</h2>
                    </div>
                    <div class="wpo-about-section-wrapper">
                        <div class="row align-items-center">
                            <div class="col-md-12 col-12">
                                <div class="wpo-about-content">
                                    {/* <!-- <h2 style="color:#D1AE6C" ></h2> --> */}
                                    <div class="wpo-about-content-inner">
                                        <p style={{ textAlign: "justify" }}>At our core, we believe in forging strong partnerships with
                                            our clients, striving to understand their unique needs and work methodologies. What
                                            truly distinguishes us is our unwavering technical expertise, enabling us to tackle
                                            projects often deemed too complex or challenging by other developers. We embrace agility
                                            in our practices and maintain open lines of communication with our clients, ensuring
                                            they are continuously informed about project developments.</p>

                                        <p>In our extensive experience, this collaborative approach consistently leads to the
                                            creation of intelligent solutions that effectively address our clients' evolving
                                            business requirements, resulting in swift turnarounds.</p>

                                        <p style={{ fontSize: "22px" }}>Our Project Planning Approach:<br /></p>

                                        <p>When embarking on any project, we adhere to a structured process that includes:</p>
                                        <ul style={{ color: "#fff", marginLeft: "70px" }}>
                                            <li>Implementing non-disclosure agreements (NDAs)</li>
                                            <li>Defining the technical scope</li>
                                            <li>Establishing processes and documentation</li>
                                            <li>Allocating responsibilities</li>
                                            <li>Creating a schedule</li>
                                            <li>Providing regular reporting</li>
                                        </ul>
                                        <p> This comprehensive approach ensures that our projects are well-organized, efficient, and
                                            executed with precision, ultimately leading to successful outcomes for our clients.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- <div class="col-lg-7 col-md-12 col-12">
                    <div class="wpo-about-img">
                        <img src="assets/images/about/about3.jpg" alt="loading"/>
                        <div class="about-img-inner">
                            <img src="assets/images/about/about4.jpg" alt="loading"/>
                        </div>
                    </div>
                </div> --> */}
                        </div>
                        {/* <!-- <div class="visible-text">
                <h2>About</h2>
            </div> --> */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default About;