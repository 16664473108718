import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { animateScroll as scroll } from 'react-scroll';


const Header = () => {



    const closeSide = () => {
        const element = document.getElementById("navbarSupportedContent");
        const ele = document.getElementById("navbar");
        element.classList.remove('x-close');
        ele.classList.remove('slideInn');

        const elements = document.getElementsByClassName("slide12");
        const eles = document.getElementsByClassName("slide11");

        // Since getElementsByClassName returns a collection, you need to loop through the elements to remove the class.
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('x-close');
        }

        for (let i = 0; i < eles.length; i++) {
            eles[i].classList.remove('slideInn');
        }

    }

    const addCl = () => {
        const element = document.getElementById("navbarSupportedContent");
        const ele = document.getElementById("navbar");
        element.classList.add('x-close');
        ele.classList.add('slideInn');

        const elements = document.getElementsByClassName("slide12");
        const eles = document.getElementsByClassName("slide11");

        // Since getElementsByClassName returns a collection, you need to loop through the elements to remove the class.
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('x-close');
        }

        for (let i = 0; i < eles.length; i++) {
            eles[i].classList.add('slideInn');
        }

    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const element = document.getElementById("scroll");

        if (window.scrollY >= 500) {
            element.classList.add('sticky-on');
        } else {
            element.classList.remove('sticky-on');

        }
    };


    return (


        <>

            <div class="wpo-site-header wpo-header-style-2">
                <nav id="scroll" class="navigation navbar navbar-expand-lg navbar-light original">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div class="mobail-menu">
                                    <button type="button" id="navbarSupportedContent" onClick={addCl} class="navbar-toggler open-btn">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar first-angle"></span>
                                        <span class="icon-bar middle-angle"></span>
                                        <span class="icon-bar last-angle"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-4">

                                <div class="navbar-header">
                                    <Link class="navbar-brand" to="/"><img src="assets/images/logo.png" alt="loading" /></Link>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-1 col-1">
                                <div id="navbar" class="collapse navbar-collapse navigation-holder">

                                    <button class="menu-close" onClick={closeSide}><i class="ti-close"></i></button>
                                    <ul class="nav navbar-nav mb-2 mb-lg-0">
                                        <li>
                                            <Link onClick={closeSide} to="/">Home</Link>

                                        </li>
                                        <li>
                                            <Link onClick={closeSide} to="/about">About Us</Link>

                                        </li>

                                        <li >
                                            <Link onClick={closeSide} to="/services">Our Services</Link>

                                        </li>

                                        <li><Link to="/blog" onClick={closeSide}>Blog</Link></li>

                                        <li><Link to="/contact" onClick={closeSide}>Contact</Link></li>


                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-2 sidebbar" style={{ textAlign: "end" }}>
                                <div class="mobail-menu d-lg-none dl-block">
                                    <button type="button">
                                        <Link to="/requirement"><i class='fas fa-edit' style={{ color: "white" }}></i></Link>
                                    </button>
                                </div>
                                <div id="navbar">
                                    <div class="slide-btns">
                                        <Link to="/requirement" class="theme-btn-s2 sideclass">Get Quote</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-2  ">

                            </div>

                        </div>
                    </div>
                </nav>
                <nav id="scroll" class="navigation navbar navbar-expand-lg navbar-light sticky-header">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div class="mobail-menu">
                                    <button type="button" id="navbarSupportedContent" onClick={addCl} class="navbar-toggler open-btn slide12">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar first-angle"></span>
                                        <span class="icon-bar middle-angle"></span>
                                        <span class="icon-bar last-angle"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-4">
                                <div class="navbar-header">
                                    <Link class="navbar-brand" to="/"><img src="assets/images/logo.png" alt="loading" /></Link>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-1 col-1">
                                <div id="navbar" class="collapse navbar-collapse navigation-holder slide11">

                                    <button class="menu-close" onClick={closeSide}><i class="ti-close"></i></button>
                                    <ul class="nav navbar-nav mb-2 mb-lg-0">
                                        <li>
                                            <Link onClick={closeSide} to="/">Home</Link>

                                        </li>
                                        <li>
                                            <Link onClick={closeSide} to="/about">About Us</Link>

                                        </li>

                                        <li >
                                            <Link onClick={closeSide} to="/services">Our Services</Link>

                                        </li>

                                        <li><Link to="/blog" onClick={closeSide}>Blog</Link></li>

                                        <li><Link to="/contact" onClick={closeSide}>Contact</Link></li>


                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-2 sidebbar" style={{ textAlign: "end" }}>
                                <div class="mobail-menu d-lg-none dl-block">
                                    <button type="button">
                                        <Link to="/requirement"><i class='fas fa-edit' style={{ color: "white" }}></i></Link>
                                    </button>
                                </div>
                                <div id="navbar">
                                    <div class="slide-btns">
                                        <Link to="/requirement" class="theme-btn-s2 sideclass">Get Quote</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-2  d-lg-none dl-block">

                            </div>

                        </div>
                    </div>
                </nav>
            </div>


        </>
    )
}

export default Header